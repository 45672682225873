<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-0">
        {{ questionText }}
      </p>
      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mt-1 pl-6">
        <div v-for="option in options1" :key="option.value">
          <v-radio class="d-inline-block" :value="option.value">
            <template #label>
              <stemble-latex :content="option.text" />
            </template>
          </v-radio>
        </div>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'UPEISafety6',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    questionNumber() {
      return this.taskState.getValueBySymbol('questionNumber').content;
    },
    questionText() {
      if (this.questionNumber.value === 1) {
        return 'What does SDS stand for in the context of laboratory safety?';
      } else if (this.questionNumber.value === 2) {
        return 'What are the steps in using a fire extinguisher properly?';
      } else {
        return 'What are the ingredients of the fuel triangle?';
      }
    },
    options1() {
      if (this.questionNumber.value === 1) {
        return [
          {text: 'Sodium dodecyl sulfate', value: '1'},
          {text: 'Safety Documentation Sheets', value: '2'},
          {text: 'Safety Documentation Systems', value: '3'},
          {text: 'Safety Data Sheets', value: '4'},
        ];
      } else if (this.questionNumber.value === 2) {
        return [
          {
            text: 'Pull pin, aim at base of fire, squeeze lever, sweep side to side',
            value: '1',
          },
          {
            text: 'Pull pin, aim at middle of fire, squeeze lever, sweep side to side',
            value: '2',
          },
          {
            text: 'Click safety pin, aim at base of fire, squeeze lever, sweep side to side',
            value: '3',
          },
          {
            text: 'Click safety pin, aim at middle of fire, squeeze lever, sweep side to side',
            value: '4',
          },
        ];
      } else {
        return [
          {text: 'Fuel, Nitrogen, Heat', value: '1'},
          {text: 'Gasoline, Diesel, Hydrogen', value: '2'},
          {text: 'Fuel, Oxygen, Heat', value: '3'},
          {text: 'Fire, Oxygen, Water', value: '4'},
        ];
      }
    },
  },
};
</script>
